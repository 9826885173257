.textLabel {
  width: 90%;
  height: 30px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px 20px;
  margin-bottom: 20px;
  outline: none;
}

.textLabel:focus {
  outline: 0.5px solid rgb(148, 148, 148);
  border: none;
}

.labelDiv {
  text-decoration: none !important;
  background-color: aqua;
}



.submitButton {
  font-size: 1em;
  text-decoration: none;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out, color 0.2s 0.3s;
  border-radius: 45px;
  height: auto;
  padding: 0.8em 2.1em;
  color: #444444;
  border: 2px solid #444444 !important;
  background-color: #fff;
  width: 200px;
}

.submitButton.border-white {
  font-size: 1.4em !important;
  border-color: white !important;
}

.submitButton:hover {
  cursor: pointer;
  padding: 0.8em 2.7em;
  background-color: #444444 !important;
  color: #fff;
  text-decoration: none;
}

.submitButton:active,
.submitButton:focus,
.submitButton.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}