.homeBanner {
  width: 100%;
  /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
  /* z-index: -1px; */
  /* margin-bottom: -230px; */
}

.banner {
  display: flex;
  align-items: center;
  width: 100%;
}

.banner-div {
  display: flex;
  flex-direction: column-reverse;
}

.banner-content {
  top: 160px;
  position: absolute;
  padding: 70px;
  width: 50%;
  
}
.mobile-banner {
  top: 0;
  position: absolute;
  padding: 10px 10px 30px 20px;
  width: 60%;
}

.title {
  font-size: 50px;
  color: #1C304F;
  text-align: left;
  width: 100%;
}

.mobile-title {
  font-size: 30px;
  color: #1C304F;
  text-align: left;
  width: 100%;
}

.text {
  margin-top: 10px;
  font-size: 15px;
  color: #1C304F;
  text-align: left;
  line-height: 1.5;
  width: 100%;
  margin-bottom: 30px;
}

.view-product-button {
  font-size: 1em;
  text-decoration: none;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out, color 0.2s 0.3s;
  border-radius: 45px;
  height: auto;
  padding: 0.8em 2.1em;
  color: #1B314F;
  border: 2px solid #1B314F !important;
  background-color: #fff;
}

.mobile-view-product-button {
  font-size: .9em;
  text-decoration: none;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out, color 0.2s 0.3s;
  border-radius: 45px;
  height: 40px;
  padding: 10px 20px;
  color: #1B314F;
  border: 2px solid #1B314F !important;
  background-color: #fff;
}

.view-product-button.border-white {
  font-size: 1.4em !important;
  border-color: white !important;
}

.mobile-view-product-button.border-white {
  font-size: .9em !important;
  border-color: white !important;
}

.view-product-button:hover, .mobile-view-product-button:hover {
  cursor: pointer;
  padding: 0.8em 2.7em;
  background-color: #1B314F !important;
  color: #fff;
  text-decoration: none;
}

.mobile-view-product-button:hover {
  padding: 10px 20px;
}

.view-product-button:active,
.view-product-button:focus,
.view-product-button.active, .mobile-view-product-button:active, .mobile-view-product-button:focus, .mobile-view-product-button.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.carousel.carousel-slider .control-arrow {
  background-color: black;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  top: 50% !important;
  /* padding: 5px; */
  margin: 15px;
}

.carousel.carousel-slider .control-arrow::before {
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  background: #000 !important;
  opacity: .5 !important;
}