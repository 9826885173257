.info {
  display: flex;
  padding: 5% 10% 20px 10%;
  height: auto;
}

.mobile-info {
  padding: 40px;
  height: auto;
}

.mobile-info .infoText {
  width: 100%;
}

.infoText {
  width: 50%;
}

.infoImg {
  width: 50%;
  padding-left: 40px;
}

/* .react-multi-carousel-item {
  width: auto !important;
} */

.infoImg img {
  width: 500px;
}

.carousel-container {
  /* padding-left: 40px; */
}

.slider-carousel {
  padding: 40px !important;
}

.react-multi-carousel-dot-list {
  margin-bottom: 100px;
}

.more-info {
  color: maroon;
  cursor: pointer;
}

.view-button {
  height: 30px;
  width: 180px;
  background-color: #262525;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  /* transition: transform 500ms ease-out; */
  border: 1px solid lightgray;
color: #FFFFFF;
border-radius: 5px;
cursor: pointer;
}

.product-view {
  width: 310px;
              height: 500px;
              /* border: .1px solid lightgray; */
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 10px;
              transition: transform 550ms;
              overflow: hidden;
              position: relative;
              transform-origin: bottom;
  transition: transform 0.3s ease-in;
  background-color: #FFFFFF;
  box-shadow: #F7F7F7;
}

.mobile-product-view {
  width: 130px;
  height: 250px;
  margin-right: 25px;
}

.product-view-img-div {
  height: 300px;
  width: 100%;
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-product-view-img-div {
  height: 250px;
  width: 100%;
}
.product-view-img {
  width: 280px;
  max-width: 280px;
  height: 250px;
  max-height: 250px;
}
.mobile-product-view-img {
  width: 95%;
  height: 100px;
}
.product-view-content {
  height: 150px;
  width: 90%;
}

.product-view-content h3 {
  font-size: 24px;
  color: #262525;
}

.mobile-product-view h3 {
  font-size: 18px;
  text-align: center;
}

.product-view-content p {
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
  margin-top: 5px;
  color: #444444;
}
.product-view-div {
  background-color: #F7F7F7;
  padding-top: 70px;
}

.product-view:hover {
  transform: scale(1.02);
}

.product-view:hover .view-button {
  visibility: inherit;
  animation: slide-up 500ms;
  animation-timing-function: ease-in-out;
  margin-bottom: 65px;
}
/* 
.product-view:hover:after .view-button {
  
  -webkit-animation: slide-down 300ms;
  -webkit-animation-timing-function: ease-out;
  margin-bottom: 150px;
} */

@keyframes slide-up {
  from {
    margin-bottom: -50px;
  }
  to {
    margin-bottom: 65px;
  }
}

/* @-webkit-keyframes slide-down {
  from {
    margin-bottom: 65px;
  }
  to {
    margin-bottom: -50px;
  }
} */

.carousel-button-group {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.right-arrow-btn, .left-arrow-btn{
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: #000000;
  color: #fff;
  opacity: 60%;
  cursor: pointer;
  transition: 0.5s;
}

.right-arrow-btn:hover, .left-arrow-btn:hover {
  opacity: 80%;
}