.contact-view a {
  text-decoration: none;
  color: red;
}

.active {
  background-color: transparent !important;
}

#header_class {
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 15px 60px 15px 60px;
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
  background-color: #FFFFFF;
  opacity: 95%;
  color: #444444;
  height: 50px;
  position: -webkit-sticky;
  height: 50px;
  justify-content: space-between;
  box-shadow: #444444;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

#header_class a, .productList, #product_list_class a {
  text-decoration: none;
  color: #444444;
  font-size: 20px;
  
}
#header_class a.active, #product_list_class a.active {
  color: #ED3833;
}

#header_class a:hover, .productList:hover, #product_list_class a:hover {
  color: #ED3833;
}


#header_class h5, .productMenu {
 margin-left: 50px;
 cursor: pointer;
 display: inline-block;
  position: relative;
}

#header_class h5::after, .productMenu::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: 
#444444;
  transform-origin: bottom left;
  transition: transform 0.3s ease-in;
}
#header_class h5:hover, .productMenu:hover {
  color: #ED3833;
}
#header_class h5:hover::after, .productMenu:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.upArrow {
  transform: scaleY(-1);
}

.menuItem {
  font-size: 15px;
  padding: 12px 20px 12px 20px;
  border-bottom: .5px solid rgb(244, 244, 244);
  text-decoration: none;
  font-weight: 500;
  width: 200px;
}

.menuItem:hover {
  background-color: transparent;
  color: #ED3833;
  
}


/* #headerLink-active {
  text-decoration: none;
  color: #ED3833;
  font-size: 20px;
} */