.activeDescription {
  border-bottom: 1px solid black;
  color: #ED3833 !important;
}

.activeEnquiry {
  border-bottom: 1px solid black;
  color: #ED3833 !important;
}

.divTitle {
  padding: 20px 20px 10px 20px;
  color: #444444;
  cursor: pointer;
}

.divTitle:hover {
  color: #ED3833;
}

.descriptionDiv, .enquiryDiv {
  border: 1px solid lightgrey;
  padding: 30px;
}

.hideEnquiryDiv {
 display: none;
}

.hideDescriptionDiv {
  display: none;
}

/* .carousel-container {
  padding-left: 0px;
  height: 350px !important;
} */

.customArrow {
  /* background: #1D3151!important; */
  height: 15px;
  width: 15px;
  margin-right: 8px;
  /* border-radius: 50%;
  display: inline-block;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
   */
}

